import React from "react";
import "./container.css";

const LoginForm = ({ onSubmit }) => {
  const [isRemembered, setIsRemembered] = React.useState(
    localStorage.getItem("remember") ? true : false
  );
  const [isPasswordShown, setIsPasswordShown] = React.useState(false);

  function handleRememberChange() {
    if (isRemembered) {
      localStorage.removeItem("remember");
      setIsRemembered(false);
    } else {
      localStorage.setItem("remember", true);
      setIsRemembered(true);
    }
  }

  return (
    <div className="container loginform">
      <div className="wrapper">
        <header>
          <h1>Welcome</h1>
          <p>Login to the Panel</p>
        </header>
        <div className="result">
          <p>Hello</p>
        </div>
        <form action={onSubmit}>
          <div className="inputs">
            <div className="input-container">
              <label htmlFor="username">Username</label>
              <input autoComplete="true" id="username" type="text" />
            </div>
            <div className="input-container">
              <label htmlFor="password">Password</label>
              <div className="container">
                <input
                  id="password"
                  type={isPasswordShown ? "text" : "password"}
                />
                <i
                  onClick={() => setIsPasswordShown(!isPasswordShown)}
                  className="bx bx-hide icon"
                />
              </div>
            </div>
            <div className="input-container hide">
              <label htmlFor="confirm">Password</label>
              <input id="confirm" type="password" />
            </div>
          </div>
          <div className="actions">
            <div onClick={() => handleRememberChange()} className="left">
              <div className={`toggle ${isRemembered ? "on" : ""}`}>
                <div className="button"></div>
              </div>
              <div className="text">Remember Me</div>
            </div>
            <div className="right">
              <a href="">Forgot Password</a>
            </div>
          </div>
          <button className="" disabled>
            <p>Login</p>
          </button>
          <a target="_blank" href="https://rgmproductions.com" className="cred">
            Powered by <span>RGM Productions Inc.</span>
          </a>
        </form>
      </div>
    </div>
  );
};

export default LoginForm;
