import React from "react";
import { Helmet } from "react-helmet";
import { Navbar, AbtHero, Footer, Credit, InfoBar } from "../../../containers";
import "./view.css";

const About = () => {
  return (
    <div className="view about">
      <Helmet>
        <title>About</title>
      </Helmet>
      <div className="container">
        <Navbar />
        <div className="content">
          <AbtHero />
          {/* <div style={{ padding: "10vh 12rem" }} className="temp">
            Featured Books
          </div> */}
          <InfoBar version={2} expand />
          <Footer />
          <Credit />
        </div>
      </div>
    </div>
  );
};

export default About;
