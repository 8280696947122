import React from "react";
import { Helmet } from "react-helmet";
import "./view.css";
import {
  Credit,
  FeatEvents,
  Footer,
  HomeHero,
  InfoBar,
  Navbar,
  Reviews,
  Summary,
} from "../../../containers";

const Home = () => {
  return (
    <div className="view home">
      <Helmet>
        <title>Home</title>
      </Helmet>
      <div className="container">
        <Navbar />
        <div className="content">
          <HomeHero />
          <Summary />
          <Reviews />
          <InfoBar expand />
          <FeatEvents />
          <Footer />
          <Credit />
        </div>
      </div>
    </div>
  );
};

export default Home;
