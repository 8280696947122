import React from "react";
import { NotificationContext } from "../../contexts";
import "./provider.css";

const NotificationProvider = ({ children }) => {
  const [notifications, setNotifications] = React.useState([]);

  function create(icon, text, borderColor, duration) {
    const id = Math.random();
    const timeStamp = new Date().getTime();

    if (notifications.length >= 4) {
      setNotifications((prev) =>
        prev.map((noti, index) => {
          if (index === 0) {
            return {
              ...noti,
              state: {
                ...noti.state,
                closing: true,
              },
            };
          }
          return noti;
        })
      );

      setTimeout(() => {
        setNotifications((prev) => prev.filter((_, index) => index !== 0));
      }, 300);
    }

    setNotifications((prev) => [
      ...prev,
      {
        id,
        timeStamp,
        icon,
        text,
        borderColor,
        duration,
        state: {
          visible: true,
          closing: false,
          moving: false,
        },
      },
    ]);

    setTimeout(() => {
      destroy(id);
    }, duration + 300);

    setTimeout(() => {
      setNotifications((prev) => prev.filter((noti) => noti.id !== id));
    }, duration + 600);
  }

  function usePreset(e, a) {
    e.preventDefault();
    if(a === "construction") {
      create(
        {
          name: "construction",
          background: "#FFC107",
          color: "#fff",
        },
        {
          title: "Under Construction",
          message: "This page is under construction.",
          color: "#000",
        },
        "#FFC107",
        5000
      );
    }
  }

  function destroy(id) {
    setNotifications((prev) =>
      prev.map((noti) => {
        if (noti.id === id) {
          return {
            ...noti,
            state: {
              ...noti.state,
              closing: true,
            },
          };
        } else {
          return {
            ...noti,
            state: {
              ...noti.state,
              moving: true,
            },
          };
        }
      })
    );

    setTimeout(() => {
      setNotifications((prev) => prev.filter((noti) => noti.id !== id));
    }, 300);
  }

  return (
    <NotificationContext.Provider value={{ create, usePreset }}>
      {children}
      {notifications.length > 0 ? (<div className="notification-container">
        <div className="wrapper">
          {notifications.map((noti, index) => (
            <div
              key={noti.id}
              className={`toast ${noti.state.closing ? "closing" : ""}`}
              style={{
                borderColor: noti.borderColor,
                background: noti.state.background
                  ? noti.state.background
                  : "#F9F9F9",
                color: noti.state.color ? noti.state.color : "#000",
                top: `${
                  notifications.slice(0, index).some(n => n.state.closing)
                    ? "-6rem"
                    : "0"
                }`,
                transition: `${
                  notifications.slice(0, index).some(n => n.state.closing)
                    ? "top 0.3s ease-in-out"
                    : ""
                }`,
              }}
            >
              <div className="wrapper">
                <div
                  style={{
                    background: noti.icon.background,
                  }}
                  className="icon"
                >
                  <span
                    className="material-symbols-outlined"
                    style={{
                      color: noti.icon.color,
                    }}
                  >
                    {noti.icon.name}
                  </span>
                </div>
                <div style={{ color: noti.text.color }} className="text">
                  <h1 className="title">{noti.text.title}</h1>
                  <p>{noti.text.message}</p>
                </div>
              </div>
              <span
                onClick={() => destroy(noti.id)}
                className="material-symbols-outlined close"
                style={{ color: noti.state.color ? noti.state.color : "#000" }}
              >
                close
              </span>
              <span
                style={{
                  background: noti.borderColor,
                  animationDuration: `${noti.duration}ms`,
                }}
                className="timer"
              ></span>
            </div>
          ))}
        </div>
      </div>) : null}
    </NotificationContext.Provider>
  );
};

export default NotificationProvider;
