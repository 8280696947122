import React from "react";
import "./container.css";

const Reviews = () => {
  const reviews = [
    {
      text: "Kabat’s beautiful, moving memoir of her brother Luke’s civil rights activism in Meridian, MS during Freedom Summer is at once a compelling historical document and deeply personal homage.  Lucien, a medical student at Stanford, left the safety of Palo Alto in the summer of 1964 to be one of more than 700 student activists risking their lives for racial equality. He’d had a good role model in own his father, blacklisted during the McCarthy era for refusing to fire other blacklisted doctors.  Forced to move to Providence, RI for the only work in his field he could get, he and his family were met by the discreet wall of genteel anti-Semitism.  It is in the context of this silent shunning that the deep bond between Julie and her brother grew and thrived, and his own activism was born. By using Luke’s letters and diary entries to tell the story, Kabat brings this extraordinary young man into vivid, breathing life.",
      attribution:
        "Marjorie Darraugh, Manager of Pegasus Books on Solano, Berkeley, California",
    },
    {
      text: "Luke did what so many did. He volunteered. Teaching, mobilizing, befriending, giving of himself in ways great and small to Freedom Summer. What is remarkable about that volunteering, is that he wrote it all down so candidly, so copiously, plainly, naively, bravely, commenting, evolving, challenging himself – and challenging each of us who read his incisive thoughts so many years later. Published, his journal entries and letters sit in the middle of a scatter of thousands of words written about Freedom Summer. But, because they were written in a moment of action, written for the purpose of grappling with his conscience, they permit us a rare journey. By writing as he did, he takes us into his mind – and into the hearts of so many others. He does something else, too. Luke was an extraordinary person taking ordinary actions in an extraordinary time. By leaving us with his unvarnished history, he shows us how simple humanity still means something – and how our own inner humanity will always mean something.",
      attribution: "Michael Eastman, Legal Historian | Archivist|Senior Librarian, Stanford University Libraries, And Center for Human Rights and International Justice, Stanford University"
    },
    {
      text: `Luke’s kid sister, Julie Kabat, has written a stirring and evocative memoir of her late brother, skillfully woven together with a biography of their family … There are places in this book that are suspenseful, others that are philosophical. But throughout the 28 chapters, plus a curiosity-quenching afterword, author’s note and coda-like acknowledgments, Julie succeeds in her goals: to praise Luke’s work, as well as that of his fellow volunteers; to take another look at her relationship with Luke and their deep affection for one another; to make him live again through his letters so that readers will really know him. 
      And as personal and heartfelt as her intentions were, Julie has also put forth an important reminder of a very divisive time in the US, with its tough lessons for the present day.`,
      attribution: "Johnnie Rodriguez, The Rhode Island Independent and The Standard Times, April 25, 2024"
    },
    {
      text: "Julie Kabat has written a remarkable book. Love Letter from Pig introduces us to her brother Luke, a third-year medical student teaching in a Mississippi Freedom School. Through Luke we meet his students, thrilled at being exposed to new knowledge; family members, concerned about his welfare; and fellow Freedom Summer volunteers. Love Letter from Pig is about idealism, courage in the face of adversity, but mostly it’s about love.",
      attribution: "Paul Murray, civil rights veteran and professor emeritus at Siena College, New York "
    }
  ];
  const reviewRefs = React.useRef([]);

  React.useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("visible");
          }
        });
      },
      { rootMargin: "0px", threshold: 0.5 }
    );

    reviewRefs.current.forEach((ref) => observer.observe(ref));

    return () => {
      reviewRefs.current.forEach((ref) => {
        if (ref instanceof Element) {
          observer.unobserve(ref);
        }
      });
    };
  }, []);

  return (
    <div className="container reviewscont">
      <header>
        <h2>What People are Saying</h2>
      </header>
      <div className="reviews">
        {reviews.map((review, index) => (
          <div
            key={index}
            ref={(el) => (reviewRefs.current[index] = el)}
            style={{ gridRow: index + 1, gridColumn: (index % 2) + 1 }}
            className="review"
          >
            <p>"{review.text}"</p>
            <span>-{review.attribution}</span>
          </div>
        ))}
      </div>
      <div className="reviews-fade">
        <a href="/another-page" className="link-to-more">Read More Reviews</a>
      </div>
    </div>
  );
};

export default Reviews;
