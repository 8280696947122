import React from "react";
import { BG1 } from "../../assets";
import { useNotifications } from "../../hooks";
import "./container.css";

const FeatEvents = () => {
  const events = [
    {
      id: 1,
      title: "Event 1 Title",
      details: {
        date: 1726418716,
        image: {
          url: null,
          alt: null,
        },
        tags: ["Support", "Meeting"],
        description:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus sit amet sapien nec felis tincidunt auctor. Nullam nec velit fermentum, fermentum arcu eget, ultricies purus. Nulla facilisi. Nullam nec velit fermentum, fermentum arcu eget, ultricies purus. Nulla facilisi.",
      },
    },
    {
      id: 2,
      title: "Event 2 Title",
      details: {
        date: 1726418716,
        image: {
          url: null,
          alt: null,
        },
        tags: ["Support", "Meeting"],
        description:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus sit amet sapien nec felis tincidunt auctor. Nullam nec velit fermentum, fermentum arcu eget, ultricies purus. Nulla facilisi. Nullam nec velit fermentum, fermentum arcu eget, ultricies purus. Nulla facilisi.",
      },
    },
    {
      id: 3,
      title: "Event 3 Title",
      details: {
        date: 1726418716,
        image: {
          url: null,
          alt: null,
        },
        tags: ["Support", "Meeting"],
        description:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus sit amet sapien nec felis tincidunt auctor. Nullam nec velit fermentum, fermentum arcu eget, ultricies purus. Nulla facilisi. Nullam nec velit fermentum, fermentum arcu eget, ultricies purus. Nulla facilisi.",
      },
    },
  ];
  const noti = useNotifications();

  return (
    <div className="container featevents">
      <header>
        <div className="left">
          <h2>Upcoming Events</h2>
          <p>
            Stay tuned for our upcoming events! We have a lot of exciting things
            planned for you.
          </p>
        </div>
        <div className="right">
          <a onClick={(e) => noti.usePreset(e, "construction")} href="/events?redir=cal">View Calendar</a>
        </div>
      </header>
      <div className="events">
        {events.map((event) => {
          const eventDate = new Date(event.details.date * 1000);
          const dayOfWeek = eventDate.toLocaleDateString("en-US", {
            weekday: "short",
          });
          const day = eventDate.getDate();
          const monthYr = eventDate.toLocaleDateString("en-US", {
            month: "short",
            year: "numeric",
          });

          return (
            <div className="event" key={event.id}>
              <img
                src={event.details.image.url || BG1}
                alt={event.details.image.alt || "Event Image"}
              />
              <div className="info">
                <small>{dayOfWeek}</small>
                <h3>{day}</h3>
                <h5>{monthYr}</h5>
              </div>
              <ul className="tags">
                {event.details.tags.map((tag, index) => (
                  <li key={index}>{tag}</li>
                ))}
              </ul>
              <h2>{event.title}</h2>
              <p>
                {event.details.description}
              </p>
              <a href="#">
                {(() => {
                  const words = [
                    "Explore",
                    "Learn More",
                    "Discover",
                    "Find Out",
                    "Check It Out",
                  ];
                  const randomIndex = Math.floor(Math.random() * words.length);
                  return words[randomIndex];
                })()}
                <span className="material-symbols-outlined">chevron_right</span>
              </a>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default FeatEvents;
