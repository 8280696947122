import { Home, Login, About } from "./views";
import { Helmet } from "react-helmet";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import { NotificationProvider } from "./providers";

function App() {
  return (
    <section className="App">
      <NotificationProvider>
        <BrowserRouter>
          <Routes>
            <Route index element={<Home />} />
            <Route path="/home" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/login" element={<Login />} />
          </Routes>
        </BrowserRouter>
      </NotificationProvider>
    </section>
  );
}

export default App;
