import React from "react";
import "./container.css";

const Credit = () => {
  return (
    <div className="container credit">
      <a target="_blank" href="https://www.rgmproductions.com">
        Designed and developed by <span>RGM Productions Inc.</span>
      </a>
    </div>
  );
};

export default Credit;
