import React from "react";
import "./container.css";
import { Banner } from "../../assets";
import { Helmet } from "react-helmet";

const Footer = ({ expanded = false }) => {
  return (
    <div className="container footer">
      <Helmet>
        <link
          href="https://unpkg.com/boxicons@2.1.4/css/boxicons.min.css"
          rel="stylesheet"
        ></link>
      </Helmet>
      <div className="top">
        <div>
          <img src={Banner} alt="" />
        </div>
        <div>
          <a href="">Home</a>
          <a href="">Reviews</a>
          <a href="">Events</a>
          <a href="">About</a>
          <a href="">Contact</a>
        </div>
        <div>
          <div className="link">
            <a href="">
              <i className="bx bxl-youtube icon" />
            </a>
            <span>Youtube</span>
          </div>
        </div>
      </div>
      <div className="divide"></div>
      <div className="alt">
        <p>&copy; {new Date().getFullYear()} Julie Kabat. All rights reserved.</p>
        <div className="left">
            <a href="">Privacy Policy</a>
            <a href="">Cookie Policy</a>
        </div>
      </div>
    </div>
  );
};

export default Footer;
