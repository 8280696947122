import React from "react";
import { Banner } from "../../assets";
import "./container.css";
import { useNotifications } from "../../hooks";

const Navbar = () => {
  const noti = useNotifications();
  const loc = window.location.pathname;

  return (
    <div className="container navbar">
      <div className="wrapper">
        <div className="left">
          <a href="/" className={`logo ${loc === "/" || loc === '/home' ? 'active' : ''}`}>
            <img src={Banner} alt="" />
          </a>
          <a className={loc === "/about" ? 'active' : ''} href="/about">About</a>
          <a onClick={(e) => noti.usePreset(e, "construction")} href="">Reviews</a>
          <a onClick={(e) => noti.usePreset(e, "construction")} href="">
            Book an Event
          </a>
          <a href="">Contact</a>
        </div>
        <div className="right">
          <a
            onClick={(e) => noti.usePreset(e, "construction")}
            className="btn"
            href=""
          >
            Order Now
          </a>
        </div>
        <div className="menu">
          <span className="material-symbols-outlined">menu</span>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
